<template>
  <select
    class="w-full text-xs border px-1 py-1 h-7 rounded"
    v-model="selected"
    :class="errorClass"
    @change="$emit('input', selected)"
  >
    <option value="">{{ placeholder }}</option>
    <option
      v-for="(option, index) in options"
      :value="option.value || option.id"
      :key="`option-${index}`"
    >
      {{ option.label || option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'form-select',
  props: ['value', 'placeholder', 'options', 'errorClass'],
  data() {
    return {
      selected: '',
    }
  },
  watch: {
    value: function(n) {
      this.selected = n
    },
  },
}
</script>

<style></style>
